/////////////////////////////////
//
// TOC
//
// general-styles) General styles
// burger-menu) Burger menu
// force-graph) Force graph
//
/////////////////////////////////

/////////////////////////////////
// general-styles) General styles
/////////////////////////////////

body {
  --color-light: white;
  --color-background: #2d3239;
  --color-backgroun-dark: #16191c;
  background-color: var(--color-background);
}

/////////////////////////////////
//burger-menu) Burger menu
/////////////////////////////////

.burger-menu__button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1500;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    border: none;
  }

  &.burger-menu--open {
    :first-child {
      transform: rotate(45deg);
    }
    :nth-child(2) {
      opacity: 0;
      transform: translateX(20px);
    }
    :nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.burger-menu__container{
  padding: 4rem 2rem;
}

/////////////////////////////////
//user-badge) User badge
/////////////////////////////////

.user-badge {
  .large {
    width: 8rem;
    height: 8rem;
  }
}

/////////////////////////////////
// force-graph) Force graph
/////////////////////////////////

.force-graph {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
}